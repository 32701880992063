import * as React from "react"
import Layout from '../components/layout'
import SignupHeader from '../components/signupheader'
import Seo from '../components/seo'
import { useTranslation } from 'react-i18next';


// markup
const IndexPage = ({pageContext}) => {

    
    const { t } = useTranslation()
  
  return (
    <Layout>
            <Seo language={pageContext.lang} siteurl={t("siteurl")} title="Colour Design by Jotun"></Seo> 
            <SignupHeader></SignupHeader>
            <main>
                <div className={`signup-form`}>
                    <div className="signup-form__inner">
                        <div className='form-response'>
                            <p>
                            {t("eventDone")}<br/>
                            <a href={t("homeurl")} className="calltoaction calltoaction--link">{t("eventDoneLinkText")}</a>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </main>
    </Layout>
  )
}

export default IndexPage
